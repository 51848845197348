<template>
    <nav id="gnb" class="gnb" v-if="!gnbHide" :class="{ active: active }">
        <svg viewBox="0 0 180 48" class="mobile_menu" v-on:click="showMenu()">
            <g>
                <polygon class="gnb_shape" points="180 48 -40 48 -40 0 180 0 164 24 180 48" />
                <rect x="124" y="15"></rect>
                <rect x="124" y="23"></rect>
                <rect x="124" y="31"></rect>
            </g>
        </svg>
        <router-link
            :to="links.sluge"
            v-for="links in gnbItems"
            :key="links"
            v-on:click="hideMenu()"
            :class="links.class"
        >
            <svg viewBox="0 0 180 48">
                <polygon class="gnb_shape" points="180 48 -40 48 -40 0 180 0 164 24 180 48" />
            </svg>
            <span class="gnb_text"> {{ links.gnbName }}</span>
            <span class="gnb_icon"></span>
        </router-link>
    </nav>
</template>

<script>
import { menuTree } from "@/js/menuTreeData";

export default {
    name: "MainNavigation",
    components: {},
    props: {
        gnbHide: { type: Boolean, default: false },
    },
    data() {
        return {
            gnbItems: menuTree,
            active: false,
        };
    },
    computed: {},
    methods: {
        showMenu() {
            this.active = !this.active;
        },
        hideMenu() {
            this.active = false;
        },
    },
    setup() {},
};
</script>

<style scoped>
.gnb {
    position: fixed;
    top: 16%;
    left: -0.2rem;
    z-index: 9999;
}
.gnb.active {
    height: auto;
}
.gnb a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 18rem;
    height: 4.8rem;
    padding: 2.4rem;
    margin-left: -3.4rem;
    transition: margin 0.4s;
    position: relative;
}
.gnb a:hover,
.gnb a.router-link-active {
    margin-left: 0;
}
.gnb a ~ a {
    margin-top: 1.6rem;
}
.gnb a svg {
    overflow: visible;

    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.gnb_shape {
    fill: #fff;
    stroke: var(--color-dark);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    filter: drop-shadow(0 0 0.6rem #6c618a20);
}
a.router-link-active .gnb_shape {
    fill: var(--color-dark);
}
.gnb_text {
    color: var(--color-dark);
    font-family: "Godo", sans-serif;
    text-align: right;
    font-weight: 700;
    font-size: 1.6rem;
    font-weight: 500;
    position: relative;
    margin-right: 1rem;
}

a.router-link-active .gnb_text {
    color: #fff;
}
.mobile_menu {
    display: none;
}

.gnb .gnb_icon {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    background: no-repeat center/contain;
}
.gnb .home .gnb_icon {
    background-image: url(@/assets/ico/ico_home.svg);
}
.gnb .home.router-link-active .gnb_icon {
    background-image: url(@/assets/ico/ico_home-w.svg);
}
.gnb .menu .gnb_icon {
    background-image: url(@/assets/ico/ico_document.svg);
}
.gnb .menu.router-link-active .gnb_icon {
    background-image: url(@/assets/ico/ico_document-w.svg);
}
.gnb .worlds .gnb_icon {
    background-image: url(@/assets/ico/ico_global.svg);
}
.gnb .worlds.router-link-active .gnb_icon {
    background-image: url(@/assets/ico/ico_global-w.svg);
}
.gnb .charactors .gnb_icon {
    background-image: url(@/assets/ico/ico_user.svg);
}
.gnb .charactors.router-link-active .gnb_icon {
    background-image: url(@/assets/ico/ico_user-w.svg);
}
.gnb .products .gnb_icon {
    background-image: url(@/assets/ico/ico_web.svg);
}
.gnb .products.router-link-active .gnb_icon {
    background-image: url(@/assets/ico/ico_web-w.svg);
}
.gnb .about .gnb_icon {
    background-image: url(@/assets/ico/ico_sonak.svg);
}
.gnb .about.router-link-active .gnb_icon {
    background-image: url(@/assets/ico/ico_sonak-w.svg);
}

@media (max-width: 1300px) {
    .gnb {
        top: 0;
        height: 4.8rem;
        padding-top: 4.8rem;
        box-sizing: content-box;
    }
    .gnb.active {
        height: 100%;
        width: 100%;
    }
    .gnb:before {
        content: "";
        display: block;
        width: 0;
        height: 100%;

        background-color: rgb(45, 40, 55, 0.9);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;

        transition: opacity 0.4s;
    }
    .gnb.active:before {
        width: 100%;
        opacity: 1;
    }
    /* 모바일메뉴 */
    .mobile_menu {
        display: block;
        cursor: pointer;
        height: 4.8rem;
        margin-left: -8rem;
        transition: margin 0.4s;
    }

    .gnb.active .mobile_menu {
        margin-left: -1rem;
    }
    .mobile_menu rect {
        display: block;
        width: 2.4rem;
        height: 0.2rem;
        fill: var(--color-dark);
    }
    .gnb a {
        width: 18rem;
        margin-left: -18rem;
        margin-top: 1.6rem;
    }

    .gnb a.router-link-active {
        margin-left: -18rem;
    }
    a.router-link-active .gnb_shape {
        stroke: #fff;
    }

    .gnb.active > a {
        margin-left: -5rem;
    }
    .gnb.active a:hover,
    .gnb.active a.router-link-active {
        margin-left: -1.2rem;
    }
    .gnb svg {
        width: 18rem;
        overflow: visible;
    }
}
@media (min-width: 1301px) {
    .gnb.active a:hover {
        margin-left: 0;
    }
}
@media (max-width: 980px) {
    .mobile_menu {
        margin-left: -10rem;
    }
}
</style>
