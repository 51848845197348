<template>
    <div class="inner">
        <div class="page after_content">
            <title-box title="세계관 소개" />
            <article class="world_des">
                <div class="world-img_box">
                    <img src="@/assets/world/intro.png" alt="소낙 세계관 소개 이미지" />
                </div>
                <div class="text_box">
                    <h3 class="main_text">언어의 한계는 <br />세상의 한계다</h3>
                    <div class="sub_text">
                        <div>
                            <p>이 세계는 이야기로 이루어진 세상입니다.</p>
                            <p>어떻게 된 영문인지,</p>
                            <p>이야기로 만들어진 이 세계가</p>
                            <p>여기저기 파괴되어가고 있습니다.</p>
                        </div>
                        <div>
                            <p>여러 <b class="accent">주인공</b>과 함께</p>
                            <p>언어와 관련된 다양한 임무를 수행하며,</p>
                            <p><b class="accent">이야기</b>를 다시 모아</p>
                            <p>이 세계를 구원해주세요!</p>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        <div class="page center" id="introduce">
            <article class="world_cards">
                <h3 class="article_title">대륙별 소개</h3>
                <ul class="card_list">
                    <li
                        class="card_item"
                        v-for="world in worldList"
                        :key="world"
                        @click="activeToggle(world.number)"
                    >
                        <div class="card_title">
                            <img
                                :src="require(`@/assets/world/${world.cardImg}.svg`)"
                                :alt="world.name"
                            />
                        </div>
                        <div class="world_image">
                            <img
                                :src="require(`@/assets/world/${world.worldImg}.png`)"
                                :alt="world.worldImgAlt"
                            />
                        </div>
                    </li>
                </ul>
            </article>
        </div>
        <WModal :worldId="worldNumber"></WModal>
        <main-footer></main-footer>
    </div>
</template>

<script>
import TitleBox from "../components/TitleBox.vue";
import WModal from "./WorldsModal.vue";
export default {
    name: "MenuView",
    components: {
        "title-box": TitleBox,
        WModal,
    },
    data() {
        return {
            worldNumber: 0,
            worldList: [
                {
                    number: 0,
                    name: "gaiac",
                    cardImg: "img_title_gaiac",
                    worldImg: "img_gaiac",
                    worldImgAlt: "gaiac",
                },
                {
                    number: 1,
                    name: "narrabilis",
                    cardImg: "img_title_narrabilis",
                    worldImg: "img_narrabilis",
                    worldImgAlt: "narrabilis",
                },
                {
                    number: 2,
                    name: "datum",
                    cardImg: "img_title_datum",
                    worldImg: "img_datum",
                    worldImgAlt: "datum",
                },
                {
                    number: 3,
                    name: "rasul",
                    cardImg: "img_title_rasul",
                    worldImg: "img_rasul",
                    worldImgAlt: "rasul",
                },
                {
                    number: 4,
                    name: "last",
                    cardImg: "img_title_last",
                    worldImg: "img_last",
                    worldImgAlt: "last",
                },
            ],
        };
    },
    computed: {},
    methods: {
        activeToggle(numbers) {
            this.worldNumber = numbers;
            this.$store.commit("showModal");
        },
    },
};
</script>
<style scoped>
.page {
    position: relative;
}
.page.after_content::after {
    font-family: "Godo";
    content: "세계관 둘러보기";
    display: inline-block;
    padding: 1.6rem 4rem;
    border-radius: 4rem;
    background-color: var(--color-deep);
    margin-top: 4rem;

    color: #fff;
    font-size: 2rem;
    text-transform: capitalize;
}
.page.after_content::before {
    content: "";
    display: block;
    border: 1rem solid transparent;
    border-bottom-color: var(--color-deep);
    border-left-color: var(--color-deep);
    position: absolute;
    bottom: -1rem;
    left: calc(50% - 0.5rem);

    transform: rotate(-45deg);
    transform-origin: center;
}
.page.center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
}
.world_des {
    display: flex;
    max-width: 100rem;
    margin: 0 auto 4rem;
    margin-top: 8rem;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.world_des > * {
    flex-grow: 1;
}
.world_des .world-img_box {
    width: 45%;
    margin-left: 5%;
}
.world_des .world-img_box img {
    width: 100%;
}
.world_des .text_box {
    text-align: left;
    width: 45%;
}
.world_des .text_box br {
    display: none;
}
.world_des .text_box .main_text {
    font-size: 4rem;
    font-family: "LeferiPoint-SpecialA";
    font-weight: 700;
    letter-spacing: -0.3rem;
    font-family: "Godo";
}
.world_des .text_box .sub_text {
    margin-top: 3.2rem;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.6;
}

.world_des .text_box .sub_text div ~ div {
    margin-top: 3.2rem;
}

.world_cards {
    padding: 4rem 0 10rem;
}
.world_cards .article_title {
    font-size: 4rem;
    font-weight: 700;
    font-family: "Godo";
}
.world_cards .card_list {
    max-width: 109rem;
    margin: 0 auto;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3.2rem;
}
.world_cards .card_item {
    width: 30%;
    position: relative;
    cursor: pointer;
}
.world_cards .card_item img {
    width: 100%;
}
.world_cards .card_title {
    width: 100%;
    height: 100%;
    background-color: var(--color-bright);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2.4rem;
    opacity: 1;
    transition: opacity 0.4s;
}
.world_cards .card_title:hover {
    opacity: 0;
}
.world_cards .card_title img {
    width: 80%;
    margin: 10% auto;
}
@media all and (max-width: 980px) {
    .page {
        position: relative;
    }
    .page.after_content::after {
        font-size: 1.6rem;
        margin-top: 6rem;
    }

    .world_des {
        display: block;
        max-width: auto;
        margin: 0 auto;
        margin-top: 0;
        padding-bottom: 10rem;
    }

    .world_des > * {
        flex-grow: 1;
    }
    .world_des .world-img_box {
        width: 90%;
        max-width: 52rem;
        margin: 2rem auto;
        opacity: 0.4;
    }
    .world_des .text_box {
        width: 90%;
        margin: -10rem auto;
        text-align: center;

        position: relative;
        z-index: 10;
    }
    .world_des .text_box .main_text {
        font-size: 4rem;
    }
    .world_des .text_box br {
        display: block;
    }
    .world_des .text_box .sub_text {
        margin-top: 2.4rem;
        font-size: 1.6rem;
    }
    .world_des .text_box .sub_text div ~ div {
        margin-top: 2rem;
    }
    .world_cards {
        padding: 2rem 0 14rem;
    }
    .world_cards .article_title {
        font-size: 2.4rem;
    }
    .world_cards .card_list {
        gap: 2rem 4vw;
    }
    .world_cards .card_item {
        width: 23vw;
        height: 23vw;
        position: relative;
        cursor: pointer;
    }

    .world_cards .card_title:hover {
        opacity: 1;
    }
    .world_cards .card_title img {
        width: 132%;
        max-width: none;
        margin: -16%;
    }
    .world_cards .world_image {
        display: none;
    }
}
</style>
