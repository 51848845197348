<template>
    <div class="member">
        <swiper
            :effect="'coverflow'"
            :grabCursor="true"
            :centeredSlides="true"
            :slidesPerView="'auto'"
            :initialSlide="1"
            :coverflowEffect="{
                rotate: 15,
                stretch: -40,
                depth: 0,
                modifier: 1,
                slideShadows: false,
            }"
            :navigation="true"
            :pagination="true"
            :modules="modules"
            class="mySwiper card_list"
        >
            <swiper-slide v-for="member in memberList" :key="member" class="card_item">
                <a :href="member.link" target="_new">
                    <div class="card_img">
                        <img :src="require(`@/assets/about/member_${member.name}.png`)" :alt="member.name" />
                    </div>
                    <h4 class="card_text">
                        <span class="role">{{ member.role }}</span>
                        <span class="name">{{ member.text }}</span>
                    </h4>
                </a>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { members } from "@/js/memberList.js";

export default {
    name: "MemberDevelope",
    components: { Swiper, SwiperSlide },
    data() {
        return {
            memberList: members.filter((member) => member.state === true && member.team == "development"),
        };
    },
    setup() {
        return {
            modules: [EffectCoverflow, Pagination, Navigation],
        };
    },
};
</script>
