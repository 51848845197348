<template>
    <div class="title_box">
        <h2>
            <p>{{ title }}</p>
        </h2>
    </div>
</template>

<script>
export default {
    name: "TitleBox",
    props: { title: { default: "제목" } },
};
</script>

<style scoped>
.title_box {
    max-width: 30rem;
    margin: 0 auto;
    margin-top: 6rem;
    padding: 3.2rem 0;
    background: url(@/assets/common/bg_title-scroll.svg) no-repeat center/contain;

    text-align: center;
}
.title_box p {
    font-family: "Godo", sans-serif;
    font-weight: 700;
    font-size: 2.4rem;
    color: var(--color-deep);
}
.title_box text {
    fill: var(--color-dark);
}
@media all and (max-width: 980px) {
    .title_box {
        margin-top: 8rem;
        width: 100%;
        font-size: 1.6rem;
        padding: 3rem 0;
    }
}
</style>
