const achivements = [
  {
    title: "게임 <잊혀진 서재 확장판>의 발자취",
    items: [
      {
        img: "achive-tumblbug.png",
        title: "새롭게 만나는 스토리 방탈출 게임",
        desc: "<잊혀진 서재> 확장판, 텀블벅 크라우드 펀딩 232% (4,658,900원) 달성",
        link: "https://tumblbug.com/lostlibrary",
        target: "_new",
      },
    ],
  },
  {
    title: "게임 <잊혀진 서재>의 발자취",
    items: [
      {
        img: "achive-gstar.png",
        title: "G-Star 2024 인디쇼케이스",
        desc: "PC 확장판 최초 공개부터 한정판 굿즈까지!",
        link: "https://www.instagram.com/p/DCIm2h6hwcP/?igsh=MXdvaHpkbGdzajVmOQ===",
        target: "_new",
      },
      {
        img: "achive-indie_craft.png",
        title: "인디크래프트 챌린저 부문 TOP 20 선정",
        desc: "인디크래프트 2024 챌린저 부문 TOP 20 선정, GXG 전시",
        link: "https://www.instagram.com/p/C_NxP7eBDGp/?igsh=MTE0Z3V5MTZuZ25iNA==",
        target: "_new",
      },
      {
        img: "achive-gstar.png",
        title: "G-Star 2023 인디쇼케이스",
        desc: "스토리 수집형 방탈출 게임을 더 많은 유저와 함께!",
        link: "https://www.instagram.com/p/Cz-5xiXBqH-/?igshid=MzRlODBiNWFlZA==",
        target: "_new",
      },
      {
        img: "achive-tumblbug.png",
        title: "모험가의 기대를 받은",
        desc: "텀블벅 크라우드 펀딩 223%(3,359,370원) 달성",
        link: "https://tumblbug.com/sonak/community/creator?ref=%EA%B2%80%EC%83%89%2F%ED%82%A4%EC%9B%8C%EB%93%9C",
        target: "_new",
      },
      {
        img: "achive-appstore.png",
        title: "퍼즐과 스토리의 만남",
        desc: "앱스토어 퍼즐 부문 최고순위 19위",
        link: "/link/",
        target: "_new",
      },
      {
        img: "achive-onestore.png",
        title: "이달의 인디게임",
        desc: "원스토어 2023년 10월 이달의 인디게임 선정, 원스토어 인기 최고순위 7위",
        link: "/link/",
        target: "_new",
      },
    ],
  },
  {
    title: "그 외 소낙의 발자취",
    items: [
      {
        img: "achive-expert.png",
        title: "심리테스트 전문가",
        desc: "인터랙티브 콘텐츠 누적 참여자 80000명 돌파 (2023.10.01 기준)",
        link: "/web",
        target: "_self",
      },
      {
        img: "achive-cliche.png",
        title: "클리셰 박사",
        desc: "클리셰개론 참여자 27000명 (2023.10.01 기준)",
        link: "https://www.metavv.com/ko/content/10045978",
        target: "_new",
      },
      {
        img: "achive-romance.png",
        title: "로맨스판타지 주인공",
        desc: "로맨스판타지 심리테스트 참여자 26000명 (2023.10.01 기준)",
        link: "https://www.metavv.com/ko/content/10861539",
        target: "_new",
      },
    ],
  },
];

export default achivements;
