<template>
    <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="'auto'"
        :initialSlide="1"
        :coverflowEffect="{
            rotate: 15,
            stretch: -100,
            depth: 300,
            modifier: 1,
            slideShadows: false,
        }"
        :pagination="true"
        :navigation="true"
        :modules="modules"
        class="mySwiper"
    >
        <swiper-slide v-for="card in cardList" :key="card" @click="activeToggle(card.number)">
            <img :src="require(`@/assets/charactor/${card.img}.png`)" :alt="card.name" />
            <h4 class="charactor__name" v-if="!(card.name == 'unknown')">
                {{ card.name }}
            </h4>
        </swiper-slide>
    </swiper>
    <CModal :charactorId="cardNumber"></CModal>
</template>

<script>
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import CModal from "@/views/charactor/CharactorModal.vue";

export default {
    name: "CharactorSpade",
    components: {
        Swiper,
        SwiperSlide,
        CModal,
    },
    data() {
        return {
            cardList: [
                { name: "unknown", img: "unknown", number: 8 },
                { name: "Ru Virtanen", img: "RuVirtanen", number: 3 },
                { name: "Penny Pita", img: "PennyPita", number: 2 },
            ],
            cardNumber: 3,
        };
    },
    computed: {},
    methods: {
        activeToggle(numbers) {
            this.cardNumber = numbers;
            this.$store.commit("showModal");
        },
    },
    beforeUnmount() {
        this.$store.commit("hideModal");
    },
    setup() {
        return {
            modules: [EffectCoverflow, Pagination, Navigation],
        };
    },
};
</script>
