export const worldData = [
  {
    id: "00",
    adjective: "문명의 시작,",
    name: "가이악",
    engName: "gaiac",
    slog: "/gaiac",
    img: "gaiac",
    title: "문명이 싹트는 시발점",
    styleBtnColor: "--btn-color: #129663;",
    content: [
      "가이악은 삼국지에 나올 법한 무협부터, 동화 속에서 보던 환상이 가득한 왕국까지 다양한 고대 설화를 기반으로 한 대륙입니다.",
      "하나의 제국과 그 제국을 맹렬히 견제하는 두 왕국, 그리고 세 나라의 경쟁 속에서 조용히 중립을 지키고 있는 작은 나라, 바스크 연합 왕국까지 네 국가가 존재합니다.",
      "각 국가는 모두 정치와 종교가 일치된 사회입니다. 세 개의 나라는 각자의 유일신이 있으며, 바스크 연합 왕국만이 세 신을 모두 섬깁니다.",
    ],
    title2: "나라빌리스 대륙과의 무역",
    content2: [
      "국혼을 통해 평화 협상을 맺고, 나라빌리스 대륙과 교역을 시작한 지 아직 40년도 채 되지 않았다고 합니다. <br/>앞으로 나라빌리스 대륙의 모험가들이 가이악 대륙을 여행하며 무엇을 발견하게 될 지, 게임을 플레이하면서 함께 알아가 보아요.",
    ],
    area: false,
    areaList: false,
  },
  {
    id: "01",
    adjective: "중세 판타지,",
    name: "나라빌리스",
    engName: "narrabillis",
    slog: "/narrabillis",
    img: "narrabillis",
    title: "마법과  신앙, 하나의 왕국",
    styleBtnColor: "--btn-color: #a5532a;",
    content: [
      "나라빌리스 대륙은 나탈리스 왕국이라는 단 하나의 국가만 존재하는 대륙입니다. 동남부는 다툼 대륙과 연결되어 있으며, 가이악대륙과도 다양한 교류가 이루어지고 있습니다.",
      "여섯 주신을 기반으로 한 <b>이니티움</b>이라는 다신교를 믿습니다. 지역별로 영주의 발언권이 강하여 봉건 제도의 모습을 취하고 있습니다.",
    ],
    title2: "마력과 신성력, 그리고 이단",
    content2: [
      "나탈리스 왕국은 건국 초기부터, 마나를 이용한 <b>마법</b>과 신의 힘을 쓰는 <b>신성력</b>, 이 두가지 종류 이외의 '마법'을 모두 <b>흑마법</b>이라고 규정하였습니다. 특히 다른 종교를 믿는 자국민을 가차 없이 처형하였습니다.",
    ],
    area: true,
    areaList: [
      {
        name: "수도, 하키아 시티",
        engName: "Hakia City",
        img: "hakia_city",
        des: [
          "나라빌리스 대륙의 모든 마법이 집적되는 나탈리스 왕국의 수도입니다. 중부 지역을 주름잡는 도시는 총 세 곳으로, 왕위 후계자 셋이 나누어 다스립니다.",
          "수도의 유행을 결정하는 문화의 도시 <b>소피엔므</b>, <br/>풍요롭게 농사가 이루어지는 밀밭의 도시 <b>트리티쿰</b>, <br/>그리고 정치와 권력의 중심 <b>하키아 시티</b>",
          "하키아 시티에는 왕립 마법학교가 있습니다. 국가가 인정한 기사가 되기 위해서는 반드시 왕립 마법학교를 통해 네 가지 직업군 중 하나의 자격을 획득해야 하죠.",
          "한 팀의 중심이 되는 요술쟁이 <b>하트</b>, <br/>창이 되어 전방을 꿰뚫는 파수꾼 <b>스페이드</b>, <br/>상처를 회복시키는 치유사 <b>클로버</b>, <br/>길을 밝히는 길잡이 <b>다이아</b>",
        ],
      },
      {
        name: "히엠스 공국",
        engName: "Hiems Duchy",
        img: "hiems_duchy",
        des: [
          "추운 눈보라를 뚫고 아름다운 설원을 지나면 히엠스 공국에 도착할 수 있습니다.",
          "이곳을 지배하는 히엠스 가문은 대대로 서리 폭풍을 잠재울 수 있는 힘을 가지고 있죠. 전해 내려오는 전설에 따르면, 서리 폭풍을 불러오는 전설 속 요정이 초대 공작과 사랑에 빠졌었기에 그녀의 후손을 대대로 지켜주고 있다고 합니다.",
          "얼음 속에서만 자라나는 서리수정은 마력 함유량이 높아 마법 연구에 사용되는데, 이 지역 사람만이 얼음 수정을 채취할 수 있습니다. 얼음수정은 여자들이 채취하기 쉬워, 이 지역은 어머니가 일을 하고 아버지가 집에 있는 게 보편적입니다.",
          "춥고 혹독한 지역이라 사람들이 무뚝뚝해 보여도, 규칙 속 다정함이 녹아있는 사회입니다.",
        ],
      },
      {
        name: "존스티나 공작령",
        engName: "Jonstina Dukedom",
        img: "jonstina_dukedom",
        des: [
          "당신은 마법의 전통과 기술의 혁신이 공존하는 존스티나 공작령에 도착했습니다! 마도공학의 정수, 공학 학문의 요람. 존스티나 영지에 도착하신 것을 환영합니다.",
          "석탄 대신 마법을 이용하는 증기 기관을 사용합니다. 불 마법을 능숙하게 사용하는 마법사가 많아, 마법과 기술을 접목한 마도공학에 꼭 필요한 열에너지를 어느 지역보다 효율적으로 공급할 수 있습니다. 공작 가문이 대대로 지니는 강한 불의 기운이 큰 몫을 했답니다.",
          "질 좋은 붉은 벽돌이 많이 생산되어, 뛰어난 건축 기술로도 유명한 도시입니다. 마법 공학자들이 모인 탑을 방문하고, 마법을 이용한 증기 기관 공장도 구경하며 색다른 모험을 즐겨보세요!",
        ],
      },
      {
        name: "프레타 후작령",
        engName: "Freta",
        img: "freta",
        des: [
          "바다가 당신을 부르는 소리가 들리시나요?<br/>모험과 전설의 도시, 프레타에 도착했습니다!",
          "왕국에서 가장 큰 항구도시이자 왕국의 해군 기지가 위치해 있는 곳이죠. 큰 항구가 있어 무역이 왕성하며, 다양한 문화가 활발히 교류하고 새로운 정보가 오가는 곳입니다.",
          "오늘의 바람은 오늘만 부는 바람, 오늘의 파도는 오늘만 치는 파도! <br/>매일이 변화무쌍한 바다로 나가보세요. 호탕한 바닷사람들이 당신을 환영해줄 거예요!",
          "풍랑에 몸을 맡기는 뱃사람들이 많아서인지 은근히 미신을 많이 믿는 분위기랍니다.최근 이상한 오염 사건이 벌어지고 있어 일부 지역이 폐쇄되자, 악령의 짓이라는 소문이 돌기도 했다는데...?",
          "매일같이 반복되는 육지 위 일상에 지쳤다면 모두 벗어던지고 바다로 떠나보세요!",
        ],
      },
      {
        name: "플라멘 신성도시",
        engName: "Flamen",
        img: "flamen",
        des: [
          "엄격한 질서가 다스리는 신성 도시, 플라멘입니다.",
          "도시 전체가 종교의 색을 띄고 있는 플라멘에선 신전의 수장인 대신관이 이 지역을 대표합니다.",
          "이렇게 종교색이 강한 지역인데도 따로 독립하지 않고 왕국의 일부로 남아있는 이유는 간결합니다. 첫 번째 왕이 빛의 신의 축복을 받는 광경을 플라멘의 초대 대신관이 직접 보았기 때문이죠. 왕국의 존재가 ‘신의 뜻’이라고 믿기에 왕국을 존중하고 있습니다.",
          "바람의 신관은 가벼운 물건이라면 어디로든 보낼 수 있는 신성력을 가지고 있어요. 국가 안에서 오가는 모든 편지를 다루며 우편업을 책임지고 있답니다!",
          "복잡한 삶에 지쳤다면, 모든 일상이 정해진 플라멘에서 마음을 비우고 오는 건 어떠세요?",
        ],
      },
    ],
  },
  {
    id: "02",
    adjective: "",
    name: "다툼",
    engName: "datum",
    slog: "/datum",
    img: "datum",
    title: "수인족이 지배하는 열대우림",
    styleBtnColor: "--btn-color: #129663;",
    content: [
      "뜨거운 태양과 숲이 가득한 대륙, 다툼<b class='eng'>Datum</b>.",
      "이 곳에서 인간은 더 이상 먹이 피라미드의 꼭대기에 있지 않습니다. 언뜻 보기에 인간과 비슷해 보이지만 동물의 특성을 가진 수인들이 이 대륙을 지배합니다. 어떤 정치 구조로 되어있으며 어떻게 살아가는지 아직 알려지지 않은 부분이 많습니다. 밝혀지지 않은 미지의 생물, 길들여지지 않은 야생 동물이 가득해 인간들은 쉽게 발을 들일 수 없습니다.",
      "나라빌리스 대륙과 연결되어, 영역 끝에 있는 사막은 신비한 무역의 중심입니다. 이 상인들만큼은 수인족의 제국 속으로 들어가도 위험하지 않다고 합니다. 어떤 비밀이 있는지 궁금하네요.",
    ],
    title2: "다른 대륙과의 관계",
    content2: [
      "나라빌리스 대륙의 일부 상인이 <br/>교역을 하며 신비로운 물건을 가져오기도 하지만, <br/>사막 상인같은 극소수의 예외를 제외하고 <br/>대부분의 경우 인간이 들어오기 어려운 곳입니다. <br/>그래서 문화나 풍습도 알려진 바가 많지 않습니다.",
      "다툼 대륙을 넘어가 더 깊은 곳으로 들어가면 <br/>버림받은 수인족들이 살아가는 어둠의 땅이 있다는 소문이 있습니다.",
    ],
    area: false,
    areaList: false,
  },
  {
    id: "03",
    adjective: "한국 현대 판타지,",
    name: "라설",
    engName: "rasul",
    slog: "/rasul",
    img: "rasul",
    title: "현실과 신화의 교차점",
    styleBtnColor: "--btn-color: #f3b436;",
    content: [
      "규칙에서 어긋난 존재들이 규칙을 지키며 살아가는 곳에 오신 것을 환영합니다.",
      "라설은 전래동화에 나올 법한 신비한 존재와 익숙한 도시의 모습이 공존하는 곳입니다. 한옥과 현대의 건물이 섞인 모습을 보면, 언뜻 보기에는 서울같기도 합니다. 하지만 자세히 보면, 어떤 부동산은 지박령이 있어 집값이 낮다고 써 있고, 건너편 정육점은 여우족을 위한 간을 특가 세일하고 있고, 가엾은 산신령 하나는 거주등록을 놓쳐 임야 양도소득세가 나왔다고 슬퍼하는 모습을 볼 수 있습니다.",
      "전래동화에 나올 법한 신비한 존재와 익숙한 도시의 모습이 공존하는 곳. 규칙에서 어긋난 존재들이 규칙을 지키며 살아가는 곳.",
    ],
    title2: false,
    content2: false,
    area: false,
    areaList: false,
  },
  {
    id: "04",
    adjective: "멀고도 가까운 미래,",
    name: "마지막 대륙",
    engName: "last",
    slog: "/last",
    img: "last",
    title: "인간이 상상했던 기술이 모두 구현된 곳",
    styleBtnColor: "--btn-color: #252525;",
    content: [
      "당신은 어둠 속 곳곳에 빛이 반짝이는 차가운 도시에 도착했습니다.",
      "이 대륙은 각 구역이 완전히 분리되어 살아가고 있습니다. 가까운 거리더라도 다른 구역이면 진입하기가 매우 어렵고 같은 구역이라면 거리가 멀더라도 충분히 가볼 수 있습니다. 현실에서 이런 기술이 있다면 어떨까 생각했던 기술이 있으십니까? 다양한 특색의 여러 구역을 다니며 상상 속 모든 기술을 만나보세요.",
      "더 이상 출퇴근이 필요없는 회사에 다니며 알약 하나로 음식을 해결해보시겠습니까? 사람같은 로봇이 있을 수도 있고, 평생 죽지 않을 수도 있습니다. 그렇지만 한 가지는 기억하세요. 이 도시에선 무엇을 믿어야할지 알 수 없어요. 당신이 방금 대화한 대상이 과연 사람일까요?",
      "현실적이고 계획적인 삶을 선호하는 당신이라면 이 도시에서 원하는 걸 얻을 수 있을 겁니다.",
    ],
    title2: false,
    content2: false,
    area: false,
    areaList: false,
  },
];
