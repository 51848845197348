<template>
  <div class="achievements__group" v-for="group in achiList" :key="group">
    <h4 class="achievements__group--title">{{ group.title }}</h4>
    <ul class="achievements__list">
      <li class="achievements__item" v-for="achi in group.items" :key="achi">
        <a :href="achi.link" class="achievements__inner" :target="achi.target" v-if="achi.link">
          <div class="achievements__item--image">
            <img :src="require(`@/assets/achive/${achi.img}`)" :alt="achi.title" />
          </div>
          <div class="achievements__item--text">
            <strong>{{ achi.title }}</strong>
            <p>{{ achi.desc }}</p>
          </div>
        </a>
        <div class="achievements__inner" v-else>
          <div class="achievements__item--image">
            <img :src="require(`@/assets/achive/${achi.img}`)" :alt="achi.title" />
          </div>
          <div class="achievements__item--text">
            <strong>{{ achi.title }}</strong>
            <p>{{ achi.desc }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import achivements from "@/js/achievementsData.js";

export default {
  name: "AchivementGroup",
  components: {},
  data() {
    return {
      achiList: achivements,
    };
  },
};
</script>
<style scoped>
/* 업적 */
.achievements__group {
  margin-top: 4rem;
}
.achievements__group--title {
  font-size: 2.6rem;
  color: var(--color-dark);
  font-weight: 600;

  text-align: left;
  display: flex;
  align-items: center;
  font-family: "Godo", sans-serif;
}
.achievements__group--title::after {
  content: "";
  display: block;
  margin-left: 3.2rem;
  flex-grow: 1;
  border-top: 1px solid var(--color-dark);
}
.achievements .achievements__list {
  width: 100%;
  margin: 2rem auto;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;
}
.achievements__inner {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  border: 3px solid var(--color-dark);
  border-radius: 1.6rem;
  padding: 2.4rem;
  background-color: var(--color-white);
  transition: box-shadow 0.4s, transform 0.4s;

  position: relative;
}
a.achievements__inner:after {
  content: "MORE +";
  display: block;
  padding: 0.4rem 0.8rem 0.3rem;
  border: 1px solid var(--color-dark);
  border-radius: 5rem;
  font-size: 1rem;
  line-height: 1;
  position: absolute;
  right: 0.6rem;
  bottom: 0.6rem;
  font-family: "Godo", sans-serif;
  font-weight: 700;
  transition: background 0.2s;
  background-color: #fff;
}
a.achievements__inner:hover:after {
  color: #fff;
  background-color: var(--color-dark);
}
.achievements__inner:hover {
  box-shadow: 0.5rem 0.5rem 0 var(--color-dark);
  transform: translate(-0.5rem, -0.5rem);
}
.achievements__item--image {
  width: 10rem;
  min-width: 10rem;
  height: 10rem;
  border-radius: 1.6rem;
  overflow: hidden;
  border: 3px solid var(--color-bright);
}
.achievements__item--text {
  text-align: left;
  flex-grow: 1;
  word-break: keep-all;
}
.achievements__item--text strong {
  font-size: 2.4rem;
  font-weight: bold;
  font-family: "Godo", sans-serif;
}
.achievements__item--text p {
  font-size: 1.8rem;
  margin-top: 1rem;
}
@media (max-width: 980px) {
  /* 업적 */

  .achievements__group--title {
    font-size: 2rem;
  }
  .achievements__group--title::after {
    margin-left: 2.4rem;
  }
  .achievements__inner {
    gap: 2rem;
    padding: 1.8rem;
  }
  .achievements__item--image {
    width: 8rem;
    min-width: 8rem;
    height: 8rem;
  }
  .achievements__item--text strong {
    font-size: 2rem;
  }
  .achievements__item--text p {
    font-size: 1.6rem;
  }
}
@media (max-width: 768px) {
  .achievements .achievements__list {
    grid-template-columns: repeat(1, 1fr);
  }
  a.achievements__inner:after {
    content: "+";
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 2.4rem;
    height: 2.4rem;
    top: -1rem;
    right: -1rem;
  }
}
</style>
