<template>
    <Teleport to="body">
        <button type="button"></button>
    </Teleport>
</template>

<script>
export default {
    name: "FloatingMenu",
    components: {},
    props: {},
    data() {
        return {};
    },
    methods: {},
    setup() {},
};
</script>

<style scoped></style>
