export const menuTree = [
    {
        gnbName: "입구",
        sluge: "/",
        class: "home",
        location: "translate(116 32)",
    },
    {
        gnbName: "목차",
        sluge: "/menu",
        class: "menu",
        location: "translate(102 32)",
    },
    {
        gnbName: "세계관",
        sluge: "/worlds",
        class: "worlds",
        location: "translate(88 32)",
    },
    {
        gnbName: "주인공",
        sluge: "/charactors",
        class: "charactors",
        location: "translate(88 32)",
    },
    {
        gnbName: "이야기",
        sluge: "/products",
        class: "products",
        location: "translate(74 32)",
    },
    {
        gnbName: "소낙 소개",
        sluge: "/about",
        class: "about",
        location: "translate(72 32)",
    },
];

export const menuLnb = [
    {
        lnbName: "empty",
        lnbLink: "#",
    },
    {
        lnbName: "empty",
        lnbLink: "#",
    },
    {
        lnbName: "소낙 인스타그램",
        lnbLink: "https://www.instagram.com/sonak.official/?utm_medium=copy_link",
    },
    {
        lnbName: "empty",
        lnbLink: "#",
    },
    {
        lnbName: "소낙 페이스북",
        lnbLink: "https://www.facebook.com/profile.php?id=100084025065880",
    },
    {
        lnbName: "empty",
        lnbLink: "#",
    },
    {
        lnbName: "empty",
        lnbLink: "#",
    },
];
