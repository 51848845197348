export const positionDate = [
    { id: 0, name: "heart", label: "하트", slug: "/" },
    { id: 1, name: "spade", label: "스페이드", slug: "/" },
    { id: 2, name: "dia", label: "다이아", slug: "/" },
    { id: 3, name: "clover", label: "클로버", slug: "/" },
];
export const charactorData = [
    {
        id: 0,
        adjective: "불의 마법사",
        name: "아이란 존스티나",
        engName: "Airan Jonstina",
        slog: "/AairanJostina",
        img: "AiranJostina",
        styleColor: "--border-color:#8a0202;--bg-color: #bf1304;",
        styleBtnColor: "--btn-color: #bf1304;",
        title: ["무능한 사람은 싫다. ", "더 싫은 건 내가 무능한 사람이 되는 거다."],
        content: [
            "&#39;역대 최강의 <b class='heart'>하트</b>&#39;라 불리는 마법사",
            "또래뿐만 아니라 이미 정식 하트가 된 선배들마저도 뛰어넘는 큰 힘을 가졌지만, 아직 컨트롤에 미숙해 마력 조절기를 활용한다.",
            "마법학교 내에서 수석을 놓친 적이 없는 학생. 노력보다는 재능이 탁월하다고 알려져 있다. 누구보다도 강한 마력을 가지고 있지만, 어린 시절 화재로 가족을 잃은 트라우마로 사람을 대상으로는 공격형 마법을 잘 사용하지 않는다.",
            "섬세하게 힘을 운용하는 일에는 마도구를 활용한다. 왕국의 유일한 공작가, 존스티나 공작가의 양녀이다. 신분은 귀족이지만 평민 출신이라는 이유로 무시받기도 한다.",
        ],
        linkSlog: "/worlds",
        link: "존스티나 공작령",
        // linkImage: "ico_jonstina-bold",
        // position: 0,
    },
    {
        id: 1,
        adjective: "얼음차사",
        name: "설수정",
        engName: "",
        slog: "/",
        img: "",
        styleColor: "--border-color:#8a0202;--bg-color: #bf1304;",
        styleBtnColor: "--btn-color: #bf1304;",
        title: ["게으르고 나태한 사람은 죽음에 이르고, ", "애써 노력하는 사람은 죽는 법이 없다."],
        content: ["라설 제국, 귀신 잡는 나례청의 젊은 차사", "독실한 불교 신자라 살생을 싫어한다.", "다른 대륙에서 왔다면서 하트니 뭐니 떠드는 외지인들이 신기하다"],
        link: "라설 제국",
        //---임시
        linkImage: "ico_triticum-bold",
        //---//임시
    },
    {
        id: 2,
        adjective: "바다가 낳은 아이",
        name: "페니 피타",
        engName: "Penny Pita",
        slog: "/PennyPita",
        img: "PennyPita",
        styleColor: "--border-color:#3D64D9;--bg-color: #18A8F1;",
        styleBtnColor: "--btn-color: #3D64D9;",
        title: ["파도가 높아도, 바람이 거세도 괜찮아!"],
        content: [
            "언제나 쾌활한 천생 바닷사람. 해군 제독인 어머니와, 해적 출신 아버지 사이에서 항상 바다를 사랑하며 자랐다. 비록 다리가 아프지만, 배 위에서 자유롭게 온 세상을 누비며 사는 꿈을 가지고 있다.",
            "바다가 좋다. 아버지가 해적이고 어머니가 해군이니 어쩌면 당연할 지도 모른다.",
            "어릴 때부터 운동이 좋아 늘 운동을 하곤 했었는데, 공놀이를 하다가 무릎이 완전히 망가져버렸다. 결국 그 후론 보호구 없이 제대로 걸을 수도 없는 몸이 되어버렸다. 그래도 특유의 민첩성을 살려, 새로 들어온 무기라는 총도 어느 정도 쓸 줄 알고 활, 검 다 기본은 한다.",
            "뛰어난 스페이드가 되는 게 꿈이었고, 어머니처럼 해군이 되고 싶었다. 그렇지만 이런 다리로는 해군에 들어갈 수 없었다. 해적이 되어 다친 다리를 낫게 할 약을 찾는 모험을 떠나려던 중, 마침 스페이드를 찾고 있던 아이란 일행을 만난다.",
        ],
        linkSlog: "/gaiac",
        link: "프레타 영지",
        // linkImage: "ico_freta-bold",
        // position: 1,
    },
    {
        id: 3,
        adjective: "조용한 물의 검사",
        name: "루 비르타넨",
        engName: "Ru Virtanen",
        slog: "/RuVirtanen",
        img: "RuVirtanen",
        styleColor: "--border-color:#1E2B6B;--bg-color: #CCD2F0;",
        styleBtnColor: "--btn-color: #1E2B6B;",
        title: ["나는 왜 이렇게 태어났을까?"],
        content: [
            "물처럼 유려한 검술, 말없는 모습",
            "잘 모르는 사람들은 루를 보고 무서워하지만, 사실 누구보다 여린 마음의 소유자이다. 엘프와 인간 사이 혼혈로 태어나 자신이 누구인지 늘 고민한다.",
            "큰 키, 적은 말수, 낮은 목소리, 날카로운 인상, 뛰어난 검 실력.",
            "주변 사람들이 무서워해서 잘 다가오지 않는 탓에, 그리고 한 곳에 정착하지 못하고 떠돌아다니는 까닭에 친구가 많지 않다.",
            "말이 없는 탓에 성격이 차가울 거라고 오해받곤 하지만... 사실 그냥 소심하고 말재주가 없어 입을 열지 않는 것뿐이다. 말을 잘 못해서 오히려 말을 할 수록 오해받다 보니 그냥 말을 하지 않는 걸 선택했다. 겉모습과 달리 부탁을 잘 거절 못 하고 해달라는 대로 해주는 성격. 이런 성격을 알고 있는 사람들에게 친구라는 명목으로 많이 이용당하기도 했다.",
        ],
        linkSlog: "/gaiac",
        link: "나라빌리스 대륙",
        //---임시
        // linkImage: "ico_triticum-bold",
        //---//임시
        // position: 1,
    },
    {
        id: 4,
        adjective: "땅의 치유사",
        name: "에르덴투야",
        engName: "Erdentuya",
        slog: "/Erdentuya",
        img: "Erdentuya",
        styleColor: "--border-color:#458C49;--bg-color: #A62E4E;",
        styleBtnColor: "--btn-color: #458C49;",
        title: ["진짜로 사람들을 도울 수 있는 건 ", "눈에 안 보이는 신이 아니라 눈에 보이는 약초야."],
        content: [
            "클로버이지만, 특이하게도 신관이나 마법사가 아닌 약초로 사람들을 돕는다. 뒷산에서 약초 캐며 사냥도 겸한 덕에 활도 잘 쏘는 편이다. 체구는 작지만 의외로 힘이 세다.",
            "나탈리스 왕국이 아닌, 다른 대륙 출신이다. 대륙에서 가장 작은 바스크 왕국의 작은 마을에서 왔다고 한다.",
            "일반적으로 신관인 경우가 많은 다른 클로버(치료사)와 달리, 약초를 다룬다. 모험이나 성공에는 별 관심이 없다. 평화롭게 하루 두 시간 일하고 열 시간 자는 게 꿈인데, 난데없이 같이 대륙을 구하는 모험가가 되자고 제안을 받아 굉장히 부담스럽다. 내가 꼭 필요하다는데 거절하긴 좀 미안해서 결국 같이 떠나긴 한다.",
        ],
        linkSlog: "/gaiac",
        link: "바스크 왕국",
        //---임시
        // linkImage: "ico_jonstina-bold",
        //---//임시
        // position: 3,
    },
    {
        id: 5,
        adjective: "바람의 성녀",
        name: "엔나타",
        engName: "",
        slog: "/",
        img: "",
        styleColor: "--border-color:#8a0202;--bg-color: #bf1304;",
        title: ["신께서 말씀하셨습니다. 가난한 성자에게 ", "금화를 건네는 것이야말로 신자의 고결한 의무라고요."],
        content: ["신성령에서 떠오르고 있는 샛별, 고귀한 성녀", "온화한 모습으로 사람들을 치료하며 따뜻한 손길을 베풀고 다닌다.", "그런데 금은보화만 보면 왜 눈이 반짝이는 걸까?"],
        link: "플라멘 신성령",
        // linkImage: "ico_flamen-bold",
    },
    {
        id: 6,
        adjective: "어둠의 강령술사",
        name: "리아 델 엘반스",
        engName: "Liatris Del Albens",
        slog: "/LiatrisDelAlbens",
        img: "LiatrisDelAlbens",
        styleColor: "--border-color:#F2B6D2;--bg-color: #EE05F2;",
        styleBtnColor: "--btn-color: #F2B6D2;",
        title: ["아름다운 것은 결코 완벽한 법이 없고, ", "빛에는 항상 그림자가 있어"],
        content: [
            "부유한 백작가라는 배경에 동화 속 공주같은 외모, 한 치의 흐트러짐없이 완벽하게 계획하는 모습- 머리부터 발끝까지 완벽해 보이는 삶이지만... 어딘지 불안해 보이기도 한다.",
            "알벤스 백작가의 막내로 패션에 관심이 많다. 가장 예쁘지도, 제일 영리하지도 않은 자식이었기에 최고로 유명한 자식이라도 되고 싶었다.",
            "알벤스 가문이 대대로 빛의 마법사였기에 당연히 자신도 빛 속성일 거라고 굳게 믿었는데... 강령술이 적성인데다가 어둠 속성이라니! 그래서인지 작위도 백작가에 빛 속성이고, 같은 포지션인 다이아로 칭송받는 실비아가 싫다.",
            "자기 일에 대한 책임감이 강하고 계획적이다. 임무를 수행하기 위해 낯선 도시, 다른 학교에 위장 전학으로 잠입해 임무를 수행하고 있는데... 솔직히 너무 무섭다.",
        ],
        linkSlog: "/gaiac",
        link: "알벤스 백작령",
        // linkImage: "ico_albens-bold",
        link02Slog: "/gaiac",
        link02: "로벨리아",
        link02Image: "로벨리아",
        // position: 2,
    },
    {
        id: 7,
        adjective: "어둠의 강령술사",
        name: "피온 아플펠트",
        engName: "",
        slog: "/",
        img: "",
        styleColor: "--border-color:#8a0202;--bg-color: #bf1304;",
        title: ["저는 새로운 게 좋아요!"],
        content: [
            "손을 한 번 움직이면 자라나는 식물들!",
            "수많은 식물들의 도움으로 길도 언제나 쉽게 찾아내버린다고 한다.",
            "알 수 없는 동식물이 가득한 정글에서 부모님의 상단을 돕고 있다.",
            "다툼 대륙과 교역할 수 있는 몇 안 되는 상단 중 하나인 비리디아 상단의 외동딸.",
            "굉장히 예의바르고 착하지만, 이해할 수 없는 엉뚱한 모습을 보이기도 한다.",
            "최고로 유명한 자식이라도 되고 싶었다.",
            "빠르게 여러 방향을 도전해 봄으로써 길을 찾는 타입의 길잡이.",
            "언제나 용감하게 달려나간다.",
            "문화적으로 나라빌리스 대륙과 다툼 대륙의 문화가 많이 섞인 동부 출신이다 보니 나탈리스 왕국 귀족 문화는 잘 모른다.",
            "아이란의 사촌이기도 하다.",
        ],
        link: "다툼 대륙",
        //---임시
        // linkImage: "ico_albens-bold",
        //---//임시
    },
    {
        id: 8,
        adjective: "만나기전의",
        name: "누군가",
        engName: "unknown",
        img: "unknown",
        title: ["조금만 기다려줘!"],
        content: [""],
    },
];
