<template>
    <Teleport to="body">
        <div class="modal" v-if="showHide">
            <button type="button" class="modal__close" @click="modalClose()">닫기</button>
            <div class="modal__box world__box">
                <div class="top">
                    <div class="world__img">
                        <img :src="require(`@/assets/world/${info[worldId].img}.jpg`)" :alt="info[worldId].link" />
                    </div>
                    <div class="world__text">
                        <h2 class="world__title">
                            <p>{{ info[worldId].engName }}</p>
                            <span>{{ info[worldId].adjective }}</span>
                            <strong>{{ info[worldId].name }}</strong>
                        </h2>
                        <h3 class="sub__title">{{ info[worldId].title }}</h3>
                        <div class="text__des">
                            <p v-for="text in info[worldId].content" :key="text" v-html="text"></p>
                        </div>
                    </div>
                </div>
                <div class="world__sub" v-if="info[worldId].title2">
                    <h4 class="sub__sub">
                        {{ info[worldId].title2 }}
                    </h4>
                    <p v-html="info[worldId].content2"></p>
                </div>
                <div class="world__area" v-if="info[worldId].area">
                    <h4 class="sub__sub">대륙의 도시</h4>
                    <ul class="area__list">
                        <li class="area__item" v-for="area in info[worldId].areaList" :key="area">
                            <div class="img__box">
                                <img :src="require(`@/assets/world/${area.img}.jpg`)" :alt="area.name" />
                            </div>
                            <div class="text__box">
                                <h4 class="area__name">
                                    <span>{{ area.engName }}</span>
                                    <strong>{{ area.name }}</strong>
                                </h4>
                                <div class="area__text">
                                    <p v-for="text in area.des" :key="text" v-html="text"></p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import { worldData } from "@/js/worldData.js";

export default {
    name: "CharactorModal",
    components: {},
    props: {
        worldId: { default: 0 },
    },
    data() {
        return {
            info: worldData,
        };
    },
    computed: {
        showHide() {
            return this.$store.state.modalState;
        },
    },
    methods: {
        modalClose() {
            this.$store.commit("hideModal");
        },
    },
    setup() {
        return {};
    },
};
</script>
<style scoped>
.world__box {
    max-width: 110rem;
    border-radius: 2.4rem;
    padding: 3.2rem 10rem 6rem;
    overflow-y: auto;
}

.world__box::-webkit-scrollbar {
    display: none;
}
.top {
    display: flex;
    justify-content: center;
    gap: 3.2rem;
    margin-top: 2rem;
}
.top .world__img {
    overflow: hidden;
    border-radius: 1.6rem;
}
.top .world__text {
    align-self: flex-end;
}
.top .world__text .world__title {
    flex-grow: 1;
    font-size: 3.2rem;
}
.top .world__text .world__title p {
    text-transform: uppercase;
    font-family: "Noto Serif", serif;
    opacity: 0.8;
    font-size: 2.4rem;
}
.top .world__text .world__title span {
    font-weight: 300;
    font-size: 3rem;
}
.top .world__text .world__title strong {
    font-family: "Godo";
}
.top .world__text .sub__title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.6rem;

    font-weight: 400;
    font-size: 1.8rem;
}
.top .world__text .sub__title::before {
    content: "";
    border-top: 1px solid var(--color-dark);
    flex-grow: 1;
}
.top .world__text .text__des {
    max-width: 42rem;
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 300;
}

.top .world__text .text__des p ~ p {
    margin-top: 1.6rem;
}

/* ========sub */

.sub__sub {
    margin: 4rem 0 1rem;
    font-size: 2.4rem;
    font-weight: 500;
    font-family: "Godo";
}
.sub__sub::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: var(--color-deep);
    margin-right: 0.8rem;
    transform-origin: center;
    transform: rotate(45deg) translate(-0.4rem);
}
.world__sub p {
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 300;
}

.world__area .area__item {
    display: flex;
    align-items: flex-start;
    gap: 2.4rem;
}

.area__item ~ .area__item {
    margin-top: 3.2rem;
}
.area__item .img__box {
    width: 30%;
    overflow: hidden;
    border-radius: 1.6rem;
}
.area__item .img__box img {
    width: 100%;
    display: block;
}
.area__item .text__box {
    width: 65%;
}
.area__item .text__box .area__name {
    font-size: 2rem;
}
.area__item .area__name span {
    font-size: 1.4rem;
    font-weight: 400;
}
.area__item .area__name strong {
    display: block;
    font-family: "Godo";
}
.area__item .area__text {
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 1.4;
}
.area__item .area__text p ~ p {
    margin-top: 1rem;
}
@media all and (max-width: 1100px) {
    .world__box {
        padding: 2.4rem;
    }

    .top {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }
    .top .world__text {
        align-self: center;
    }
    .top .world__text .world__title {
        font-size: 3.2rem;
    }

    .top .world__text .text__des {
        margin-top: 2.4rem;
    }

    .top .world__text .text__des p ~ p {
        margin-top: 1.2rem;
    }

    /* ========sub */
    .world__sub,
    .world__area {
        max-width: 46.2rem;
        margin: 0 auto;
    }
    .world__area .area__item {
        display: block;
    }

    .area__item ~ .area__item {
        margin-top: 4rem;
    }
    .area__item .img__box {
        width: 100%;
        height: 28rem;
    }

    .area__item .text__box {
        width: 100%;
        margin-top: 1.6rem;
    }
}

@media all and (max-width: 768px) {
    .top .world__text .world__title {
        font-size: 2.4rem;
    }
    .top .world__text .world__title span {
        font-size: 2.1rem;
    }
    .top .world__text .world__title p {
        font-size: 1.4rem;
    }
    .top .world__text .sub__title {
        font-size: 1.6rem;
    }
    .top .world__text .text__des {
        font-size: 1.4rem;
    }
    /* ========sub */
    .sub__sub {
        font-size: 2rem;
        font-weight: 500;
    }
    .world__sub p {
        font-size: 1.4rem;
    }
    .world__sub br {
        display: none;
    }
    .area__item .text__box .area__name {
        font-size: 1.8rem;
    }
    .area__item .area__text {
        font-size: 1.4rem;
    }
}
</style>
