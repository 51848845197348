<template>
    <div class="inner member">
        <div class="member__list">
            <ul>
                <li v-for="member in memberList" :key="member" class="card_item">
                    <a :href="member.link" target="_new">
                        <div class="card_img">
                            <img :src="require(`@/assets/about/member_${member.name}.png`)" :alt="member.name" />
                        </div>
                        <h4 class="card_text">
                            <span class="role">{{ member.role }}</span>
                            <span class="name">{{ member.text }}</span>
                        </h4>
                    </a>
                </li>
            </ul>
        </div>

        <button type="button" @click="oldMembersView = !oldMembersView" class="show-more">
            <span v-show="!oldMembersView">졸업한 멤버 더보기 +</span>
            <span v-show="oldMembersView">졸업한 멤버 닫기</span>
        </button>
        <div class="member__list old" v-show="oldMembersView">
            <ul>
                <li v-for="member in oldMembers" :key="member" class="card_item">
                    <a :href="member.link" target="_new">
                        <div class="card_img">
                            <img :src="require(`@/assets/about/member_${member.name}.png`)" :alt="member.name" />
                        </div>
                        <h4 class="card_text">
                            <span class="role">{{ member.role }}</span>
                            <span class="name">{{ member.text }}</span>
                            <span class="period">{{ member.period }}</span>
                        </h4>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { members } from "@/js/memberList.js";
export default {
    name: "MemberAll",
    components: {},
    data() {
        return {
            memberList: members.filter((member) => member.state === true),
            oldMembers: members.filter((member) => member.state === false),
            oldMembersView: false,
        };
    },
};
</script>
<style scoped>
.inner.member {
    max-width: none;
    max-width: 160rem;
}
.member__list.old {
    margin-top: 40px;
}
.show-more {
    border: 1px solid var(--color-deep);
    border-radius: 50px;
    background-color: var(--color-bright);
    padding: 10px 20px;
    margin: 24px 0;
    cursor: pointer;
}
.inner.member ul {
    display: flex;
    justify-content: center;
    gap: 2rem 0;
    width: 100%;
    flex-wrap: wrap;
}
.inner.member ul .card_item {
    opacity: 1;
    width: 15%;
}
.inner.member ul .card_item a {
    user-select: all;
    pointer-events: all;
}
.inner.member ul .card_item a .card_img {
    max-width: 14rem;
    width: 100%;
}
.inner.member ul .card_item a .card_img img {
    width: calc(100% - 0.8rem);
}
.inner.member ul .card_item a .card_text .role {
    font-size: 1.6rem;
}
.inner.member ul .card_item a .card_text .name {
    font-size: 2rem;
}
.inner.member ul .card_item a .card_text .period {
    font-size: 1.4rem;
    margin-top: 1rem;
    opacity: 0.5;
    display: block;
}
@media all and (max-width: 980px) {
    .inner.member ul .card_item {
        width: 20%;
    }
}
@media all and (max-width: 620px) {
    .inner.member ul .card_item {
        width: 25%;
    }
    .inner.member ul .card_item a .card_text .role {
        font-size: 1.2rem;
    }
    .inner.member ul .card_item a .card_text .name {
        font-size: 1.6rem;
    }
}
@media all and (max-width: 620px) {
    .inner.member ul .card_item {
        width: 33%;
    }
}
</style>
