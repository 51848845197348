<template>
    <div class="inner charactor">
        <div class="top">
            <title-box title="주인공 소개" />
            <ul class="position_tab">
                <li class="top_tab-item" :class="item.class" v-for="item in position" :key="item">
                    <router-link :to="item.class">
                        <img :src="require(`@/assets/ico/${item.img}`)" :alt="item.class" />
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="content">
            <router-view></router-view>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
import TitleBox from "../components/TitleBox.vue";

export default {
    name: "CharactorView",
    components: {
        "title-box": TitleBox,
    },
    data() {
        return {
            position: [
                {
                    class: "heart",
                    img: "ico_heart-w.png",
                },
                {
                    class: "spade",
                    img: "ico_spade-w.png",
                },
                {
                    class: "dia",
                    img: "ico_dia-w.png",
                },
                {
                    class: "clover",
                    img: "ico_clover-w.png",
                },
            ],
        };
    },
    mounted() {
        if (this.$route.path == "/charactors") {
            this.$router.push({ path: "/heart" });
        }
    },
};
</script>
<style scoped>
.inner.charactor {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.position_tab {
    max-width: 40rem;
    margin: 2rem auto 3rem;
    display: flex;
    justify-content: center;
    gap: 2.4rem;
    align-items: center;
}

.top_tab-item a {
    display: block;
    width: 6rem;
    height: 6rem;
    border-radius: 4rem;
    padding: 1rem;
    background-color: var(--color-bright);
    cursor: pointer;
}
.top_tab-item:hover a,
.top_tab-item a.router-link-active {
    background-color: var(--color-dark);
}
.top_tab-item img {
    width: 100%;
}
.content {
    height: 60vh;
}
@media all and (max-width: 980px) {
    .position_tab {
        gap: 1.6rem;
    }
    .top_tab-item a {
        width: 6rem;
        height: 6rem;
        padding: 1rem;
    }
}
</style>
<style>
.charactor .swiper {
    height: 100%;
    max-height: 56rem;
    max-width: 140rem;
    margin: 0 auto;
}
.charactor .swiper-wrapper,
.charactor .swiper-slide {
    height: 94%;
}

.charactor .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 36rem;
    max-height: 492rem;
    background: url(@/assets/charactor/frame.png) no-repeat center/92% 92%;
    background-color: #fff;
    border-radius: 1rem;
    opacity: 0;
    cursor: pointer;
    transition-property: transform, opacity, background-color;
}
.charactor .swiper-slide .charactor__name {
    color: var(--color-deep);
    position: absolute;
    bottom: -2.8rem;
    font-size: 4rem;
    font-family: "Ms Madi", cursive;
}
.charactor .swiper-slide-active:hover,
.charactor .swiper-slide-active:active {
    background-color: var(--color-dark);
    background-image: url(@/assets/charactor/frame-w.png);
}
.charactor .swiper-slide-active:hover .charactor__name,
.charactor .swiper-slide-active:active .charactor__name {
    color: #fff;
    text-shadow: 0 0 0.4rem var(--color-deep), 0 0 1rem #fff, 0 0 20px var(--color-dark),
        0 0 30px var(--color-deep);
}
.charactor .swiper-slide-active,
.charactor .swiper-slide-prev,
.charactor .swiper-slide-next {
    opacity: 1;
}
.charactor .swiper-slide img {
    height: 80%;
}
.charactor .swiper-slide-active {
}
@media all and (max-width: 420px) {
    .charactor .swiper {
        height: 80%;
    }

    .charactor .swiper-slide {
        max-width: 28rem;
    }
}
</style>
