<template>
    <main-navigation></main-navigation>
    <div class="container">
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
    </div>
    <top-float-benner></top-float-benner>
    <side-banner></side-banner>
</template>
<script>
// @ is an alias to /src
import MainNavigation from "@/components/Navigation.vue";
import TopFloatBanner from "@/components/TopFloatBanner.vue";
import SideBanner from "@/components/SideBanner.vue";
import FloatingMenu from "@/components/FloatingMenu.vue";

export default {
    components: {
        "main-navigation": MainNavigation,
        "top-float-benner": TopFloatBanner,
        "side-banner": SideBanner,
        "float-menu": FloatingMenu,
    },
};
</script>

<style>
body {
    overflow-x: hidden;
}
#app {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow-y: scroll;
}

.inner {
    width: 80%;
    margin: 0 auto;
}
.gnb.main {
    left: -22rem;
}
.container {
    width: calc(100% - 22rem);
    width: 100%;
    overflow: hidden;
}
body,
.inner {
    transition: opacity 1s ease;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
@media all and (max-width: 1200px) {
    .inner {
        width: 100%;
        padding: 0 1.6rem;
    }
}
</style>
