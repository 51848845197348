<template>
    <a :href="bannerLink" class="top__banner">
        <p class="banner-text" v-html="bannerText" v-for="text in repeatCount" :key="text"></p>
    </a>
</template>

<script>
export default {
    name: "TopFloatBanner",
    data() {
        return {
            bannerText:
                "당신이 처음 만나는 스토리 수집형 방탈출 게임 <b><잊혀진 서재></b>! 지금 바로 다운로드하세요!",
            repeatCount: 8,
            bannerLink: "http://sonaksdp.com/link/",
        };
    },
};
</script>

<style>
.top__banner {
    width: 100%;
    padding: 1rem;
    line-height: 1;
    background-color: #ffffff90;

    display: flex;

    text-align: left;
    white-space: nowrap;
    font-size: 1.4rem;

    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 999999;
}
.top__banner:hover {
    background-color: var(--color-dark);
    background-color: #2c3e50;
    color: #fff;
}
.top__banner .banner-text {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    animation: marquee 10s linear infinite;
    animation-direction: alternate;
}
.top__banner:hover .banner-text {
    animation-play-state: paused;
}
.top__banner .banner-text i {
    font-size: 0.9em;
}
.top__banner .banner-text span {
    text-decoration: underline;
}

.top__banner .banner-text::before,
.top__banner .banner-text::after {
    content: "";
    display: block;
    width: 4.8rem;
    border-top: 1px solid var(--color-dark);
}
.top__banner:hover .banner-text::before,
.top__banner:hover .banner-text::after {
    border-color: #fff;
}
@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
@media all and (max-width: 620px) {
    .top__banner {
        background-color: #2c3e50;
        color: #fff;
        top: auto;
        bottom: 0;
    }
    .top__banner .banner-text::before,
    .top__banner .banner-text::after {
        border-color: #fff;
    }
}
</style>
