import { createStore } from "vuex";

export default createStore({
  state: { modalState: false, menuState: false, targetPath: "/" },
  getters: {},
  mutations: {
    showModal(state) {
      state.modalState = !state.modalState;
    },
    hideModal(state) {
      state.modalState = false;
    },
  },
  actions: {},
  modules: {},
});
