<template>
    <div class="modal">
        <div class="modal_box">
            <CharactorCard></CharactorCard>
        </div>
    </div>
</template>

<script>
import CharactorCard from "@/views/charactor/CharactorModal.vue";
export default {
    name: "ModalWindow",
    components: { CharactorCard },
    props: {
        modalType: { type: String, default: "" },
    },
    data() {
        return {};
    },
};
</script>
