import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebaseConfig from "../firebaseConfig";

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

//컴포넌트
import MaodalLayout from "@/components/MaodalLayout";
import mainFooter from "@/components/MainFooter";

const app = createApp(App);

app.config.globalProperties.$imageUrl = "gs://lost-story.appspot.com";

app.component("window-modal", MaodalLayout);
app.component("main-footer", mainFooter);

app.use(store);
app.use(router);
app.use(firebaseApp);

app.mount("#app");
require("./style/common.css");

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
