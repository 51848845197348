<template>
  <Teleport to="body">
    <aside class="aside">
      <swiper
        :slidesPerView="'auto'"
        :initialSlide="1"
        :pagination="pagination"
        :modules="modules"
        class="bannerSwiper"
        :loop="true"
        :autoplay="{
          delay: 2500,
        }"
      >
        <swiper-slide v-for="banner in bannerList" :key="banner">
          <a :href="banner.url" traget="_blank">
            <img :src="require(`@/assets/banner/${banner.img}`)" :alt="banner.alt" />
          </a>
        </swiper-slide>
      </swiper>
      <div class="banner_pagination"></div>
    </aside>
  </Teleport>
</template>

<script>
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "SideBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {},
  data() {
    return {
      bannerList: [
        {
          url: "https://www.instagram.com/p/DCIm2h6hwcP/?igsh=MXdvaHpkbGdzajVmOQ==",
          img: "banner_gstar2024.png",
          alt: "지스타 2024 참가",
        },
        {
          url: "https://page.onstove.com/indieboostlab/global/list/130007",
          img: "banner_stoveindie_demo.png",
          alt: "슬기로운 데모생활 진행",
        },
        {
          url: "https://sonaksdp.com/link/",
          img: "palace_update_banner.png",
          alt: "잊혀진 서재 청룡의 해 업데이트 안내 - 신규출시! 푸른 용의 궁 거리 스킨, 신규 챕터 업데이트! 제2장 다섯소녀",
        },
      ],
      cardNumber: 4,
    };
  },
  methods: {},
  setup() {
    return {
      pagination: {
        el: ".banner_pagination",
      },
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style scoped>
.aside {
  position: fixed;
  right: 4rem;
  top: 50%;
  z-index: 900;

  width: 16rem;
  max-height: 60%;
  transform: translateY(-50%);
  transition: opacity 1s;
  opacity: 1;
}
.aside.fade {
  opacity: 0;
}
.aside .banner_pagination {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}
@media all and (max-width: 1620px) {
  .aside {
    display: none;
  }
}
</style>
<style>
.aside .banner_pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 0 4px;
}
.aside .banner_pagination .swiper-pagination-bullet-active {
  background-color: var(--color-dark);
}
</style>
