<template>
    <Teleport to="body">
        <div class="modal" v-if="showHide">
            <button type="button" class="modal__close" @click="modalClose()">닫기</button>
            <div class="modal__box charactor__box">
                <div class="card__item img__card">
                    <img :src="require(`@/assets/charactor/${info[charactorId].img}.png`)" :alt="info[charactorId].name" />
                </div>
                <div class="card__item info__card">
                    <h2 class="charactor__name">
                        <span>{{ info[charactorId].adjective }}</span>
                        <strong>{{ info[charactorId].name }}</strong>
                        <p>{{ info[charactorId].engName }}</p>
                    </h2>
                    <div class="charactor__title">
                        <p v-for="text in info[charactorId].title" :key="text" v-html="text"></p>
                    </div>
                    <div class="charactor__content">
                        <p class="charactor__des" v-for="text in info[charactorId].content" :key="text" v-html="text"></p>
                    </div>
                    <div class="links">
                        <a class="area" v-if="info[charactorId].linkImage" @click="moveRoute()">
                            <div class="area__img">
                                <img :src="require(`@/assets/ico/${info[charactorId].linkImage}.png`)" :alt="info[charactorId].link" />
                            </div>
                            <span>{{ info[charactorId].link }}</span>
                        </a>
                        <a href="#" class="area" v-if="!(info[charactorId].position === undefined)">
                            <div class="area__img">
                                <img :src="require(`@/assets/charactor/ico_${position[info[charactorId].position].name}-w.png`)" :alt="position[info[charactorId].position].name" />
                            </div>
                            <span>{{ position[info[charactorId].position].label }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import { charactorData, positionDate } from "@/js/charactorData.js";

export default {
    name: "CharactorModal",
    components: {},
    props: {
        charactorId: { default: 0 },
    },
    data() {
        return {
            info: charactorData,
            position: positionDate,
        };
    },
    computed: {
        showHide() {
            return this.$store.state.modalState;
        },
    },
    methods: {
        modalClose() {
            this.$store.commit("hideModal");
        },
        moveRoute() {
            this.$router.push({
                path: "/worlds",
                hash: "#introduce",
            });
        },
    },
    setup() {
        return {};
    },
};
</script>
<style scoped>
.modal__close {
    bottom: 4rem;
}
.charactor__box {
    display: flex;
    height: 80%;
    max-height: 80%;
    gap: 2.4rem;
    max-width: 104rem;
    width: 100%;
    word-break: keep-all;
    background-color: transparent;
}

.charactor__box .card__item {
    width: 50%;
    height: 100%;
    flex-grow: 1;
    background-color: #fff;
    border-radius: 2.4rem;
    padding: 3.2rem;
}
/* 이미지 */
.img__card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(@/assets/charactor/frame.png) no-repeat center/ 94% 94%;
}
.img__card img {
    max-width: 90%;
    max-height: 90%;
}

/* 정보 */
.info__card {
    overflow-y: auto;
    overflow-x: hidden;
}
.info__card::-webkit-scrollbar {
    display: none;
}
/* 주인공 이름 */
.charactor__name {
    text-align: center;
    padding: 1rem 0 2rem;
    font-size: 4rem;
    line-height: 1.2;

    position: relative;
}
.charactor__name span {
    display: block;
    font-weight: 400;
    font-size: 3.2rem;
}
.charactor__name p {
    position: absolute;
    right: 0;
    bottom: -1rem;

    font-family: "Ms Madi", cursive;
    opacity: 0.3;
    font-size: 5rem;
    white-space: nowrap;
}
/* 주인공 한마디 */
.charactor__title {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    width: 100%;
    padding: 1.6rem;
    color: #666;
    line-height: 1.4;

    position: relative;
}
.charactor__title p {
    font-family: "Godo", sans-serif;
}
.charactor__title::before,
.charactor__title::after {
    content: "";
    display: block;
    width: 1rem;
    height: 100%;
    border: 1px solid #999;

    position: absolute;
    top: 0;
}
.charactor__title::before {
    left: -1rem;
    border-right: none;
}
.charactor__title::after {
    right: -1rem;
    border-left: none;
}
/* 주인공 소개 */
.charactor__content {
    width: 90%;
    text-align: left;
    margin: 2.4rem auto;
    font-size: 1.6rem;
    line-height: 1.4;

    overflow-y: auto;
}
.charactor__content p ~ p {
    margin-top: 1rem;
}
/* 주인공 관련 링크 */
.links {
    display: flex;
    justify-content: center;
    gap: 1.6rem;
    margin-top: 4rem;
}
.links .area {
    display: block;
    text-align: center;
    color: var(--color-dark);
}
.links .area span {
    display: block;
    margin-top: 1rem;
    font-size: 1.6rem;
}
.links .area__img {
    padding: 0.8rem;
    width: 8rem;
    height: 8rem;
    margin: 0 auto;
    border-radius: 50%;
    background-color: var(--color-dark);
}
.links .area__img img {
    width: 100%;
}
@media all and (max-width: 1100px) {
    /* 닫기버튼 */

    .charactor__box {
        display: block;
        margin-top: 4rem;
        width: 90%;
        max-width: 40rem;
        overflow-y: auto;
        background-color: #fff;
        border-radius: 2.4rem;
    }
    .charactor__box::-webkit-scrollbar {
        display: none;
    }
    .charactor__box .card__item {
        width: 100%;
        height: auto;
        border-radius: 0;
    }
    /* 이미지 */
    .img__card {
        background-size: auto 96%;
    }
    .img__card img {
        max-width: 94%;
        max-height: 94%;
    }

    /* 정보 */
    .info__card {
        overflow: visible;
    }
    .info__card::-webkit-scrollbar {
        display: none;
    }
    /* 주인공 이름 */
    .charactor__name {
        padding: 0 0 2rem;
        font-size: 3.2rem;
    }
    .charactor__name span {
        font-size: 2rem;
    }
    .charactor__name p {
        bottom: -0.6rem;
        font-size: 3.2rem;
    }
    /* 주인공 한마디 */
    .charactor__title {
        font-size: 1.6rem;
        margin-top: 2rem;
    }

    /* 주인공 소개 */
    .charactor__content {
        font-size: 1.4rem;

        overflow-y: auto;
    }
    .charactor__content p ~ p {
        margin-top: 1rem;
    }
    /* 주인공 관련 링크 */

    .links .area__img {
        width: 6rem;
        height: 6rem;
    }
}
@media all and (max-width: 1100px) {
}
@media (max-width: 520px) {
    .charactor__title p {
        display: inline;
    }
}
</style>
<style>
.charactor__des b.heart {
    color: #bf1304;
}
</style>
