<template>
    <div class="page__menu">
        <div class="menu__img-wrap">
            <svg viewBox="0 0 1300 1000">
                <image href="@/assets/web_menupage.jpg" width="1300" height="1000" />
                <g fill="transparent">
                    <polygon
                        points="492 625 218 754 218 335 492 266 492 625"
                        class="link__worlds"
                        @click="pushTo('/worlds')"
                    />
                    <polygon
                        points="1088 762 814 632 814 276 1088 346 1088 762"
                        class="link__charactor"
                        @click="pushTo('/charactors')"
                    />
                    <g class="kuka-talk">
                        <rect x="520" y="408" width="260" height="48" rx="16" />
                        <polygon points="640 456 660 456 650 470" />
                        <!-- 456 -->
                        <text x="544" y="436">{{ kukaTalk }}</text>
                    </g>
                    <rect
                        x="500"
                        y="246"
                        width="300"
                        height="410"
                        class="link__about"
                        @click="pushTo('/about')"
                    />
                    <rect
                        x="550"
                        y="690"
                        width="210"
                        height="222"
                        class="link__product"
                        @click="pushTo('/products')"
                    />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "MenuView",
    components: {},
    data() {
        return { kukaTalk: "안녕하세요! 소낙에 오신것을 환영해요!" };
    },
    computed: {},
    methods: {
        pushTo(link) {
            this.$router.push({ path: link });
        },
    },
    mounted() {},
    setup() {},
};
</script>
<style></style>
<style scoped>
.footer {
    display: none;
}
.page__menu {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    background-color: #a6714b;
}

.menu__img-wrap {
    position: relative;
    margin: 0 auto;
    /* max-width: 130rem; */
    height: 100%;
}
.page__menu svg {
    width: 100%;
    height: 100%;
}
.page__menu svg image {
    height: 100%;
}
.page__menu svg polygon,
.page__menu svg rect {
    cursor: pointer;
}

@media all and (max-width: 1800px) {
    .page__menu {
        background-size: auto 100%;
    }
}
@media all and (max-width: 1080px) {
    .page__menu svg image {
        transform-origin: center;
        transform: scale(1.4);
    }
}
@media all and (max-width: 768px) {
    .page__menu {
        background-image: linear-gradient(to bottom, #a6714b 50%, #eacdab 50%);
    }
}
.kuka-talk {
    animation: kuka 1s ease-out infinite;
    animation-direction: alternate-reverse;
    transform-origin: bottom;
}
.kuka-talk rect,
.kuka-talk polygon {
    fill: rgba(255, 255, 255, 0.8);
}
.kuka-talk text {
    font-size: 1.4rem;
    fill: var(--color-deep);
    font-weight: 700;
}
@keyframes kuka {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
}
@media all and (max-width: 768px) {
    .kuka-talk rect,
    .kuka-talk polygon,
    .kuka-talk text {
        transform: scale(2);
        transform-origin: center;
    }
}
@media all and (max-width: 420px) {
    .kuka-talk {
        display: none;
    }
}
</style>
