<template>
    <div class="inner">
        <main class="main">
            <section class="title_wrap">
                <div class="title_box">
                    <div class="img_box">
                        <img src="@/assets/common/logo.svg" alt="sonak logo" />
                    </div>
                    <div class="text_box">
                        <h1 class="text-title">SONAK</h1>
                        <span class="text-sub_title">Story Design Project</span>
                    </div>
                </div>
                <router-link to="/menu" class="btn">탐험하기</router-link>
                <ul class="download-links">
                    <li class="download-links__item" v-for="link in download" :key="link">
                        <a :href="link.url" class="download-links__link" :class="link.class">
                            <img :src="require(`@/assets/ico/${link.img}`)" :alt="link.alt" />
                        </a>
                    </li>
                </ul>
            </section>
            <section class="door">
                <router-link to="/menu">
                    <img src="@/assets/main/main/door-left_part.png" class="left" />
                    <img src="@/assets/main/main/door-right_part.png" class="right" />
                    <img
                        src="@/assets/main/main/door-frame_part.png"
                        alt="explore sonak"
                        class="frame"
                    />
                </router-link>
            </section>
        </main>
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
    name: "MainView",
    components: {},
    data() {
        return {
            download: [
                {
                    url: "https://play.google.com/store/apps/details?id=com.sonak.LostLibrary&pcampaignid=web_share",
                    img: "ico_download-playstore.png",
                    alt: "구글 플레이 스토어에서 다운로드",
                    class: "google",
                },
                {
                    url: "https://apps.apple.com/kr/app/%EC%9E%8A%ED%98%80%EC%A7%84-%EC%84%9C%EC%9E%AC/id6451304049",
                    img: "ico_download-apple.png",
                    alt: "앱스토어 다운로드",
                    class: "apple",
                },
                {
                    url: "https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000771781",
                    img: "ico_download-onestore.png",
                    alt: "원스토어 다운로드",
                    class: "one",
                },
            ],
        };
    },
};
</script>
<style scoped>
.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.inner {
    width: 100%;
    min-height: 100vh;
    padding: 0 22rem;
    background-image: linear-gradient(to bottom, transparent 80vw, var(--color-bright) 80vw);

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.main {
    width: 100%;
    max-width: 1400px;
    min-width: 720px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}
.main::after {
    content: "";
    display: block;
    width: 100vw;
    height: 50vh;
    background-color: var(--color-bright);

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.title_wrap {
    width: 50%;
    height: 100%;
}

.title_wrap .title_box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;

    text-align: left;
    line-height: 1;
    color: var(--color-dark);
}
.title_wrap .img_box {
    width: 10rem;
}
.title_wrap .img_box img {
    max-height: 100%;
}
.title_box .text-title {
    font-size: 8rem;
    font-family: "Godo";
}
.title_box .text-sub_title {
    font-size: 3.2rem;
    font-family: "Noto Serif", serif;
    font-weight: 700;

    opacity: 0.7;
}
.title_wrap .btn {
    display: block;
    padding: 1.8rem 2rem 1.9rem;
    max-width: 38rem;
    border: 0.2rem solid var(--color-dark);
    border-radius: 10rem;
    margin: 4rem auto 0;

    color: var(--color-dark);
    font-size: 2.4rem;
    font-weight: 700;

    transition: background 0.2s;
}
.title_wrap .btn:hover {
    background-color: var(--color-dark);

    color: var(--color-white);
}
.download-links {
    display: flex;
    justify-content: center;
    margin: 2rem auto 0;
    gap: 2rem;
}

.download-links__link {
    display: block;
    padding: 1rem;
    border-radius: 50%;
    width: 5rem;
    background-color: var(--color-bright);
    border: 1px solid var(--color-dark);
    cursor: pointer;
    transition: all 0.2s;

    position: relative;
    z-index: 2;
}

.download-links__link.google {
    padding-left: 1.2rem;
    padding-right: 0.8rem;
}
.download-links__link.apple {
    background-color: var(--color-dark);
}
.download-links__link:hover {
    transform: scale(1.1);
}

/* ==================문 */
.door {
    margin-bottom: -2rem;
    width: 50%;
    position: relative;
    cursor: pointer;
}
.door .frame {
    display: inline-block;
    width: 82.4%;
    position: relative;
    z-index: 3;
}
.door::before {
    content: "";
    display: block;
    width: 70%;
    height: 92%;
    background-color: var(--color-bright);
    position: absolute;
    left: 15%;
    bottom: 1.1%;
}
.door .left,
.door .right {
    position: absolute;
    width: 30%;
    bottom: 1.1%;
    z-index: 2;

    transition: transform 0.4s;
}
.door .left {
    right: 50%;
    transform-origin: left;
}
.door:hover .left {
    transform: skewY(-5deg) scaleX(0.9);
}
.door .right {
    left: 50%;
    transform-origin: right;
}
.door:hover .right {
    transform: skewY(5deg) scaleX(0.9);
}

@media all and (max-width: 1640px) {
    .inner {
        background-image: linear-gradient(to bottom, transparent 74%, var(--color-bright) 74%);
    }
}
@media all and (max-width: 1540px) {
    .inner {
        background-image: linear-gradient(to bottom, transparent 68%, var(--color-bright) 68%);
    }
}
@media (max-width: 1360px) {
    .title_box .text-title {
        font-size: 6rem;
    }
    .title_box .text-sub_title {
        font-size: 2.4rem;
    }
    .title_wrap .btn {
        padding: 1.6rem 2rem 1.7rem;
        max-width: 32rem;
        margin: 3.2rem auto 0;

        font-size: 2.4rem;
    }
}
@media (max-width: 980px) {
    .inner {
        flex-direction: column;
        padding: 0 10%;
    }
    .footer {
        position: initial;
        width: 100%;
    }
    .main {
        flex-direction: column;
        justify-content: center;
        min-width: 0;
        padding-bottom: 6rem;
    }
    .title_wrap {
        width: 100%;
    }
    .title_wrap .title_box {
        display: block;
        text-align: center;
    }
    .title_wrap .img_box {
        width: 8rem;
        margin: 0 auto 1.6rem;
    }

    .title_box .text-title {
        font-size: 4rem;
    }
    .title_box .text-sub_title {
        font-size: 2.4rem;
    }
    .title_wrap .btn {
        display: none;
    }

    /* ==================문 */
    .door {
        width: 100%;
        max-width: 40rem;
        margin-top: 2.4rem;
    }
}
</style>
