// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import MainView from "@/views/MainView.vue";
import MenuView from "@/views/MenuView.vue";
import WorldView from "@/views/WorldsView.vue";
import CharactorView from "@/views/CharactorView.vue";

// charactor
import heartView from "@/views/charactor/CharactorHeart.vue";
import spadeView from "@/views/charactor/CharactorSpade.vue";
import diaView from "@/views/charactor/CharactorDia.vue";
import cloverView from "@/views/charactor/CharactorClover.vue";

// about
import AboutView from "@/views/AboutView.vue";
import MemberAll from "@/views/about/MemberAll.vue";
import MemberPlanning from "@/views/about/MemberPlanning.vue";
import MemberArt from "@/views/about/MemberArt.vue";
import MemberDesign from "@/views/about/MemberDesign.vue";
import MemberDevelope from "@/views/about/MemberDevelope.vue";
// import CouponPage from "@/views/CouponPage.vue";

const routes = [
    {
        path: "/",
        name: "main",
        component: MainView,
    },
    // {
    //     path: "/coupon",
    //     name: "coupon",
    //     component: CouponPage,
    // },
    {
        path: "/menu",
        name: "menu",
        component: MenuView,
    },
    {
        path: "/worlds",
        name: "worlds",
        component: WorldView,
    },
    {
        path: "/charactors",
        name: "charactors",
        component: CharactorView,
        children: [
            {
                path: "/heart",
                name: "heart",
                props: true,
                component: heartView,
            },
            {
                path: "/spade",
                name: "spade",
                component: spadeView,
            },
            {
                path: "/dia",
                name: "dia",
                component: diaView,
            },
            {
                path: "/clover",
                name: "clover",
                component: cloverView,
            },
        ],
    },
    {
        path: "/products",
        name: "products",
        component: () => import("../views/ProductView.vue"),
        children: [
            {
                path: "/game",
                name: "game",
                props: true,
                component: () => import("@/views/product/ProductGame.vue"),
            },
            {
                path: "/sns",
                name: "sns",
                component: () => import("@/views/product/ProductSns.vue"),
            },
            {
                path: "/web",
                name: "web",
                component: () => import("@/views/product/ProductWeb.vue"),
            },
        ],
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
        children: [
            {
                path: "/member_all",
                name: "member_all",
                props: true,
                component: MemberAll,
            },
            {
                path: "/member_planning",
                name: "member_planning",
                props: true,
                component: MemberPlanning,
            },
            {
                path: "/member_art",
                name: "member_art",
                component: MemberArt,
            },
            {
                path: "/member_design",
                name: "member_design",
                component: MemberDesign,
            },
            {
                path: "/member_develope",
                name: "member_develope",
                component: MemberDevelope,
            },
        ],
    },
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
});

export default router;
