const firebaseConfig = {
    apiKey: "AIzaSyCr00V6YUjiuIIaR9E9FmdZeTNlP3IyGQI",
    authDomain: "lost-story.firebaseapp.com",
    databaseURL: "https://lost-story-default-rtdb.firebaseio.com",
    projectId: "lost-story",
    storageBucket: "lost-story.appspot.com",
    messagingSenderId: "784806489240",
    appId: "1:784806489240:web:11331443c69b54b4e0b4ed",
    measurementId: "G-SWMW2DKX69",
};
export default firebaseConfig;
