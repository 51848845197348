<template>
    <footer class="footer" id="footer">
        <ul class="info-list">
            <li class="info-list__item">
                <span class="item__label">E-MAIL</span>
                <a href="mailto:sonaksdp@gmail.com" class="item__link"> sonaksdp@gmail.com </a>
            </li>
            <li class="info-list__item">
                <span class="item__label">INSTAGRAM</span>
                <a href="https://www.instagram.com/sonak.official" class="item__link">
                    @sonak.official
                </a>
            </li>
        </ul>
        <p class="copy">Copyright 2023.Sonak. All rights reserved.</p>
    </footer>
</template>

<script>
export default {
    name: "mainFooter",
};
</script>

<style scoped>
.footer {
    padding: 2rem 0;
    font-size: 1.4rem;
    line-height: 1.4;
}
.info-list {
    display: flex;
    justify-content: center;
    gap: 2.4rem;
}
.info-list__item ~ .info-list__item {
    position: relative;
}
.footer .info-list__item ~ .info-list__item::before {
    content: "";
    display: block;
    height: 1.4rem;
    border-left: 0.1rem solid #2c3e50;

    position: absolute;
    top: 50%;
    left: -1.2rem;
    transform: translateY(-50%);
    opacity: 0.8;
}
.footer .item__label {
    font-weight: bold;
}
.footer .item__link {
    display: inline-block;
    padding: 0 0.8rem;
    margin-left: 0.4rem;
    text-decoration: underline;
}
.footer .item__link:hover,
.footer .item__link:active {
    color: var(--color-white);
    background-color: var(--color-deep);
}
.copy {
    margin-top: 1rem;
    font-style: italic;
}
@media (max-width: 620px) {
    .info-list {
        flex-direction: column;
        gap: 0.4rem;
    }
    .footer .info-list__item ~ .info-list__item::before {
        display: none;
    }
}
</style>
