<template>
    <div class="inner">
        <div class="page">
            <title-box title="소낙 소개" />
            <article class="about_text">
                <div class="about-img_box">
                    <swiper
                        :slidesPerView="'auto'"
                        :initialSlide="1"
                        :pagination="pagination"
                        :modules="modules"
                        class="teamImageSwiper"
                        :loop="true"
                        :autoplay="{
                            delay: 2500,
                        }"
                    >
                        <swiper-slide v-for="image in teamImageList" :key="image">
                            <img :src="require(`@/assets/about/${image.img}`)" :alt="image.alt" />
                        </swiper-slide>
                    </swiper>
                    <div class="team_pagination"></div>
                </div>
                <div class="text_box">
                    <h3 class="main_text">
                        <p class="sub">Story Design Project</p>
                        SONAK
                    </h3>
                    <div class="sub_text">
                        <p v-for="message in greetingMessage" :key="message">
                            <span v-for="phara in message" :key="phara">{{ phara }}</span>
                        </p>
                    </div>
                </div>
            </article>
        </div>
        <div class="page center">
            <article class="achievements">
                <h3 class="article_title">소낙 업적</h3>
                <achivement-group />
            </article>
        </div>
        <div class="page center">
            <article class="member_cards">
                <h3 class="article_title">소낙 구성원</h3>
                <div class="team_tab">
                    <ul class="tab_list">
                        <li
                            class="tab_item"
                            v-for="item in tabItems"
                            :key="item"
                            :class="item.class"
                        >
                            <router-link :to="item.slug"> {{ item.text }} </router-link>
                        </li>
                    </ul>
                </div>
                <div class="content">
                    <router-view></router-view>
                </div>
            </article>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import TitleBox from "../components/TitleBox.vue";
import AchivementGroup from "./about/AchivementGroup.vue";

export default {
    name: "AboutView",
    components: { Swiper, SwiperSlide, "title-box": TitleBox, "achivement-group": AchivementGroup },
    data() {
        return {
            width: 0,
            tabItems: [
                { text: "모두", slug: "/member_all" },
                { text: "기획", slug: "/member_planning" },
                { text: "아트디자인", slug: "/member_art" },
                { text: "개발", slug: "/member_develope" },
            ],
            teamImageList: [
                { img: "img-team-01.png", alt: "G-STAR 2023 기념사진" },
                { img: "img-team-02.png", alt: "G-STAR 2023 잊혀진서재 배너랑 같이!" },
                { img: "img-team-03.png", alt: "G-STAR 부꾸" },
                { img: "img-team-04.png", alt: "G-STAR 2023 기념사진 2" },
            ],
            greetingMessage: [
                ["스토리 디자인 프로젝트, ", "소낙의 공식 웹사이트에 오신 것을 환영합니다."],
                [
                    "소낙은 매력적인 오리지널 IP와 스토리로 ",
                    "다양한 매체를 통해 트랜스미디어 스토리텔링을 추구하고 있습니다.",
                ],
                [
                    "스토리 수집형 방탈출 게임 <잊혀진 서재>은 ",
                    "구글 플레이스토어, 앱스토어, 원스토어에서 만나실 수 있습니다.",
                ],
                [
                    "<잊혀진 서재>는 원스토어 이달의 인디게임으로 선정되었으며 ",
                    "지스타 2023 인디쇼케이스에 참여해 큰 사랑을 받았습니다.",
                ],
                [
                    "소낙은 게임 외에도 다양한 콘텐츠를 창작하며",
                    "끊임없이 새로운 도전을 하고 있습니다.",
                ],
            ],
        };
    },
    mounted() {
        if (this.$route.path == "/about") this.$router.push({ path: "/member_all" });
    },
    setup() {
        return {
            pagination: {
                el: ".team_pagination",
            },
            modules: [Autoplay, Pagination, Navigation],
        };
    },
};
</script>
<style scoped>
.page {
    position: relative;
}
.page.center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
}
.page.after_content::after {
    content: "소낙을 소개합니다";
    display: inline-block;
    padding: 1.6rem 4rem;
    border-radius: 4rem;
    background-color: var(--color-deep);
    margin-top: 4rem;

    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    text-transform: capitalize;
}
.page.after_content::before {
    content: "";
    display: block;
    border: 1rem solid transparent;
    border-bottom-color: var(--color-deep);
    border-left-color: var(--color-deep);
    position: absolute;
    bottom: -1rem;
    left: calc(50% - 0.5rem);

    transform: rotate(-45deg);
    transform-origin: center;
}

.about_text {
    display: flex;
    max-width: 120rem;
    margin: 8rem auto;
    align-items: center;
    justify-content: center;
    gap: 8rem;
}

.about_text > * {
    flex-grow: 1;
}

.about_text .about-img_box {
    width: 55%;
}
.about_text .about-img_box .team_pagination {
    display: flex;
    justify-content: center;
    padding: 16px 0;
}
.about_text .about-img_box img {
    width: 100%;
    border-radius: 1.6rem;
}
.about_text .text_box {
    text-align: left;
    width: 45%;
}
.about_text .text_box .main_text {
    font-size: 4.8rem;
    font-family: "LeferiPoint-SpecialA";
    font-weight: 600;
    line-height: 1.4;
}

.about_text .text_box .main_text .sub {
    font-size: 1.8rem;
    letter-spacing: -0.1rem;
    font-weight: 400;
}
.about_text .text_box .sub_text {
    margin-top: 2rem;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.6;
    word-break: keep-all;
}

.about_text .text_box .sub_text p ~ p {
    margin-top: 1.6rem;
}
.about_text .text_box .sub_text span {
    display: block;
}
.content {
    min-height: 20rem;
    width: 100%;
}
@media all and (max-width: 980px) {
    .page {
        position: relative;
    }

    .page.after_content::after {
        font-size: 1.6rem;
    }

    .about_text {
        display: block;
        max-width: auto;
        margin: 0 auto;
        margin-top: 0;
        padding-bottom: 4rem;
    }

    .about_text > * {
        flex-grow: 1;
    }
    .about_text .about-img_box {
        width: 70%;
        max-width: 52rem;
        margin: 4rem auto;
    }

    .about_text .text_box {
        width: 90%;
        margin: 0 auto;
        text-align: center;

        position: relative;
        z-index: 10;
    }
    .about_text .text_box .main_text {
        font-size: 4rem;
    }

    .about_text .text_box .main_text .sub {
        font-size: 1.6rem;
    }
    .about_text .text_box .sub_text {
        margin-top: 1.6rem;
        font-size: 1.6rem;
        word-break: keep-all;
    }
    .about_text .text_box .sub_text p ~ p {
        margin-top: 1rem;
    }
    .about_text .text_box .sub_text span {
        display: inline;
    }
}

/* ===============업적 */
.achievements {
    max-width: 100rem;
    margin: 8rem auto 0;
}
.achievements .article_title {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 8rem;
    font-family: "Godo", sans-serif;
}
@media (max-width: 980px) {
    /* 업적 */
    .achievements .article_title {
        font-size: 3.2rem;
        margin-bottom: 6rem;
    }
}
@media (max-width: 768px) {
    .achievements {
        margin-top: 0;
    }
}
/* ===============member */
.member_cards {
    padding: 4rem 0 10rem;
    width: 100%;
}
.member_cards .article_title {
    font-size: 4rem;
    font-weight: 700;
    font-family: "Godo", sans-serif;
}

/* 탭 */
.member_cards .team_tab {
    width: 100%;
    padding: 0 2.4rem;
    margin: 4rem auto;
}
.member_cards .tab_list {
    display: flex;
    gap: 1.6rem;
    justify-content: center;
    width: 100%;
}
.member_cards .tab_list .tab_item {
    width: 15%;
}
.member_cards .tab_list .tab_item a {
    padding: 0.8rem 2.4rem;
    border: 0.1rem solid var(--color-dark);
    border-radius: 4rem;
    display: block;
    font-size: 2rem;
    color: var(--color-dark);
}
.member_cards .tab_list .tab_item:hover a,
.member_cards .tab_list .tab_item a.router-link-active {
    background-color: var(--color-dark);
    color: #fff;
}
@media all and (max-width: 980px) {
    /* ===============member */
    .member_cards .article_title {
        font-size: 3.2rem;
    }
    .member_cards .team_tab {
        margin: 4rem auto;
        padding: 0;
    }
    .member_cards .tab_list {
        flex-wrap: wrap;
        gap: 1.2rem;
    }
    .member_cards .team_tab .tab_item {
        width: calc(50% - 0.8rem);
    }
    .member_cards .team_tab .tab_item a {
        font-size: 1.4rem;
    }
}
</style>
<style>
.inner.member {
    overflow: visible;
    position: relative;
}
.member_cards .member {
    position: relative;
}
.member_cards .card_list {
    margin: 0 auto;
    overflow: visible;
    padding-bottom: 4.4rem;
    padding-top: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    position: initial;
}
.member_cards .card_item {
    width: 18rem;
    position: relative;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.4s;

    user-select: none;
    pointer-events: none;
}
.member_cards .card_item.swiper-slide-prev,
.member_cards .card_item.swiper-slide-next {
    pointer-events: all;
    opacity: 1;
}
.member_cards .card_item.swiper-slide-active {
    opacity: 1;
    pointer-events: all;
}

.member_cards .card_item a {
    color: var(--color-dark);
}
.member_cards .card_item .card_img {
    width: 18rem;
    margin: 0 auto;
}
.member_cards .card_item .card_img img {
    width: 100%;
}

.member_cards .card_item .card_text .role {
    display: block;
    font-size: 1.6rem;
    margin-top: 1rem;
    font-weight: 300;
}
.member_cards .card_item .card_text .name {
    font-size: 2.8rem;
    text-transform: uppercase;
    margin-top: 0.6rem;
    display: block;
}

@media (max-width: 1200px) {
    /* .member_cards .card_list {
    width: 80%;
  } */
}
@media (max-width: 980px) {
    .inner.member {
        padding: 0;
    }
    .member_cards .content {
        overflow: visible;
    }
}
</style>
<style>
.about_text .about-img_box .team_pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 4px;
}
.about_text .about-img_box .team_pagination .swiper-pagination-bullet-active {
    background-color: var(--color-dark);
}
</style>
